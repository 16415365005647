@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Quicksand&display=swap');

*, *::after, *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Quicksand', sans-serif;
}

.bg-image {
  background-image: url("../public/assets/cupcake.jpg");

  filter: blur(3px);
  -webkit-filter: blur();

  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-text {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: rgb(173, 173, 173);
  font-weight: bold;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}